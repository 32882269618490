import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
import pic05 from '../assets/images/pic05.jpg'
import pic06 from '../assets/images/pic06.jpg'
import pic08 from '../assets/images/pic08.jpg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'

const Landing = (props) => (
  <Layout>
    <Helmet
      title="Vision"
      meta={[
        { name: 'description', content: 'Tell us' },
        { name: 'keywords', content: 'Renewable, Custom, Audio, Design, Engineering, Ceramic, Speakers, Drivers' },
      ]}
    >
    </Helmet>

    <BannerLanding />

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>Design</h2>
          </header>
          <p>It starts with design, beauty.  Then, we choose superb drivers and layer on gorgeous materials - often combining ceramics with sustainable woods like bamboo and reclaimed pine.</p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <Link to="/sustainable" className="image">
            <img src={pic01} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Ceramics</h3>
              </header>
              <p>Ceramics made from natural materials that are sustainable in all manufacturing stages – from material extraction to production and packaging, through to processing. They are stable, resistant to high temperatures and do not expand or shrink with temperature variations.</p>
              <ul className="actions">
                <li><Link to="/sustainable" className="button">Learn more</Link></li>
              </ul>
            </div>
          </div>
        </section>
        <section>
          <Link to="/sustainable" className="image">
            <img src={pic08} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Drivers</h3>
              </header>
              <p>Silky smooth sound produced by only the finest quality components.  This gives a lush air to the sound and ensures there are no harsh or overbearing tones.  Bringing only joy to your ears.</p>
              <ul className="actions">
                <li><Link to="/sustainable" className="button">Learn more</Link></li>
              </ul>
            </div>
          </div>
        </section>
        <section>
          <Link to="/sustainable" className="image">
            <img src={pic04} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Testing</h3>
              </header>
              <p>Come with us in listening and evaluating any of our creations in your own environment.  Most rooms are treated moderately acoustically and average 14 by 19 feet.</p>
              <ul className="actions">
                <li><Link to="/sustainable" className="button">Learn more</Link></li>
              </ul>
            </div>
          </div>
        </section>
      </section>
    </div>

  </Layout>
)

export default Landing